import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { Fragment, useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import { StyleSheet, View } from 'react-native';
import Text from '../presentational/Text';
import { Chip } from '@rneui/themed';
import AddTenantForm from '../containers/Form/AddTenantForm';

function Home({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    auth: { user },
    tenant: {
      activeFilter,
      searchResults,
      input: { name },
    },
  } = useStoreState((state) => state);

  const { getTenantsThunk, resetTenantInputAction, setActiveFilterAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetTenantInputAction();
      getTenantsThunk();
    }
    return () => {
      setActiveFilterAction('All');
    };
  }, [isFocused]);

  return (
    <Page.Default
      title='All Tenants'
      navBar={
        <Fragment>
          <Row style={{ paddingTop: '1rem' }}>
            <Col xs={12} md={12}>
              <SearchBar.Tenant />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Chip
                onPress={() => setActiveFilterAction('All')}
                buttonStyle={
                  activeFilter === 'All'
                    ? styles.activeChipButton
                    : styles.disabledChipButton
                }
                titleStyle={
                  activeFilter === 'All'
                    ? styles.activeChipText
                    : styles.disabledChipText
                }
              >
                All
              </Chip>
            </Col>
            <Col xs={12} md={4}>
              <Chip
                onPress={() => setActiveFilterAction('Active')}
                type='outline'
                buttonStyle={
                  activeFilter === 'Active'
                    ? styles.activeChipButton
                    : styles.disabledChipButton
                }
                titleStyle={
                  activeFilter === 'Active'
                    ? styles.activeChipText
                    : styles.disabledChipText
                }
              >
                Active
              </Chip>
            </Col>
            <Col xs={12} md={4}>
              <Chip
                onPress={() => setActiveFilterAction('Inactive')}
                type='outline'
                buttonStyle={
                  activeFilter === 'Inactive'
                    ? styles.activeChipButton
                    : styles.disabledChipButton
                }
                titleStyle={
                  activeFilter === 'Inactive'
                    ? styles.activeChipText
                    : styles.disabledChipText
                }
              >
                Inactive
              </Chip>
            </Col>
          </Row>
          <Row style={{ flex: 1, height: '100%' }}>
            <Col xs={12} md={12} style={{ flex: 1, height: '100%' }}>
              <List.Tenants navigation={navigation} />
            </Col>
          </Row>
        </Fragment>
      }
      body={
        <Fragment>
          <Row>
            <Col xs={12} md={3}>
              <View
                style={{
                  padding: '16px',
                  borderLeft: '4px solid #0088CE',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 5px grey',
                }}
              >
                <div>
                  <Text
                    style={{
                      color: '#0088CE',
                      fontSize: '1.8vh',
                      fontWeight: '600',
                    }}
                  >
                    {searchResults.reduce(
                      (partialSum, a) => partialSum + a.userCnt,
                      0
                    )}
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      color: '#76787A',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                    }}
                  >
                    Total Users
                  </Text>
                </div>
              </View>
            </Col>
            <Col xs={12} md={3}>
              <View
                style={{
                  padding: '16px',
                  borderLeft: '4px solid #0088CE',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 5px grey',
                }}
              >
                <div>
                  <Text
                    style={{
                      color: '#0088CE',
                      fontSize: '1.8vh',
                      fontWeight: '600',
                    }}
                  >
                    {searchResults.reduce(
                      (partialSum, a) => partialSum + a.tenantCnt,
                      0
                    )}
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      color: '#76787A',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                    }}
                  >
                    Tenants
                  </Text>
                </div>
              </View>
            </Col>
            <Col xs={12} md={3}>
              <View
                style={{
                  padding: '16px',
                  borderLeft: '4px solid #0088CE',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 5px grey',
                }}
              >
                <div>
                  <Text
                    style={{
                      color: '#0088CE',
                      fontSize: '1.8vh',
                      fontWeight: '600',
                    }}
                  >
                    {searchResults.reduce(
                      (partialSum, a) => partialSum + a.locationCnt,
                      0
                    )}
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      color: '#76787A',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                    }}
                  >
                    Locations
                  </Text>
                </div>
              </View>
            </Col>
            <Col xs={12} md={3}>
              <View
                style={{
                  padding: '16px',
                  borderLeft: '4px solid #0088CE',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 5px grey',
                }}
              >
                <div>
                  <Text
                    style={{
                      color: '#0088CE',
                      fontSize: '1.8vh',
                      fontWeight: '600',
                    }}
                  >
                    {searchResults[0]?.sysupTime}
                  </Text>
                </div>
                <div>
                  <Text
                    style={{
                      color: '#76787A',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                    }}
                  >
                    System Uptime
                  </Text>
                </div>
              </View>
            </Col>
          </Row>
          <Row style={{ flex: 1, height: '100%' }}>
            <AddTenantForm
              editable={
                user.role.toLowerCase() === 'admin' ||
                user.role.toLowerCase() === 'customer admin'
              }
              buttons={[
                <Button.SaveTenant
                  navigation={navigation}
                  message='Added tenant'
                  disabled={!name}
                />,
              ]}
            />
          </Row>
        </Fragment>
      }
    ></Page.Default>
  );
}

export default Home;

const styles = StyleSheet.create({
  activeChipButton: {
    backgroundColor: '#0088CE',
  },
  activeChipText: {
    color: 'white',
    fontSize: '1.4vh',
    fontWeight: '600',
  },
  disabledChipButton: { border: '1px solid #76787A', backgroundColor: 'white' },
  disabledChipText: { color: '#00205B', fontSize: '1.4vh', fontWeight: '600' },
});
