import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Text from '../presentational/Text';
import { Badge, ListItem } from '@rneui/themed';
import { ActivityIndicator, View } from 'react-native';
import CustomModal from '../presentational/Modal';
import { Chart } from 'react-google-charts';
import Button from '../presentational/Button';

function ReportingPage({ navigation, ...props }) {
  const isFocused = useIsFocused();

  const {
    getReportsThunk,
    setReportsModalAction,
    setToolsInputAction,
    setReportListDataAction,
    resetToolsByEngineAction,
    setEngineReportModalAction,
  } = useStoreActions((actions) => actions);

  const {
    searchResults,
    reports,
    upcomingActionsChartData,
    overdueActionsChartData,
    statusChartData,
    oemStatusChartData,
    toolsByStatusMap,
    toolsByOEMStatusMap,
    reportsLoading,
    hasUpcomingMaintenance,
    hasPastDueMaintenance,
  } = useStoreState((store) => store.tools);
  useEffect(() => {
    if (isFocused) {
      getReportsThunk();
    }
    return () => {
      resetToolsByEngineAction();
    };
  }, [isFocused]);

  const toolSelected = (tool) => {
    if (tool.partNumber && tool.serialNumber) {
      const foundTool = searchResults.find(
        (item) =>
          item.partNumber === tool.partNumber &&
          item.serialNumber === tool.serialNumber
      );
      setToolsInputAction(foundTool);
      setReportsModalAction(false);
      setEngineReportModalAction(false);
      navigation.navigate('Edit Tool', { id: foundTool.id });
    }
  };

  return (
    <Page.Protected style={{ flex: 1, height: '100%' }}>
      <CustomModal.Reports onPress={toolSelected}></CustomModal.Reports>
      <CustomModal.EngineReport
        onPress={toolSelected}
      ></CustomModal.EngineReport>
      <View style={{ height: '100%' }}>
        <View
          style={{
            flex: 1,
            height: '100%',
            padding: '1rem',
            backgroundColor: '#f8f9fa',
          }}
        >
          <Row>
            <Col xs={12} sm={12} style={{ justifyContent: 'center' }}>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Button onPress={() => setEngineReportModalAction(true)}>
                  Engine Report
                </Button>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              margin: '.75rem',
              borderRadius: '12px',
              backgroundColor: 'white',
              boxShadow: '1px 0px 10px grey',
              overflow: 'hidden',
            }}
          >
            <Col xs={12} sm={6}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    marginRight: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
                <View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                      color: '#0088CE',
                    }}
                  >
                    Statuses
                  </Text>
                </View>
                <View
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
              </View>
              {reportsLoading && (
                <View
                  pointerEvents='none'
                  style={{
                    paddingTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F5FCFF88',
                    zIndex: 1000,
                  }}
                >
                  <ActivityIndicator size='large' />
                </View>
              )}
              {!reportsLoading && (
                <Row style={{ flex: 1 }}>
                  <Col xs={12} sm={12} style={{ flex: 1 }}>
                    <Chart
                      chartType='PieChart'
                      width='100%'
                      height='250px'
                      options={{
                        tooltip: {
                          isHtml: true,
                          text: 'value',
                        },
                        pieSliceText: 'value',
                        pieHole: 0.4,
                        is3D: false,
                        colors: [
                          '#109618',
                          '#990099',
                          '#0099C6',
                          '#DC3912',
                          '#FF9900',
                          '#3366CC',
                          '#DD4477',
                        ],
                      }}
                      data={statusChartData}
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={12} sm={6}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    marginRight: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
                <View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                      color: '#0088CE',
                    }}
                  >
                    OEM Statuses
                  </Text>
                </View>
                <View
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
              </View>
              {reportsLoading && (
                <View
                  pointerEvents='none'
                  style={{
                    paddingTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F5FCFF88',
                    zIndex: 1000,
                  }}
                >
                  <ActivityIndicator size='large' />
                </View>
              )}
              {!reportsLoading && (
                <Row style={{ flex: 1 }}>
                  <Col xs={12} sm={12} style={{ flex: 1 }}>
                    <Chart
                      chartType='PieChart'
                      width='100%'
                      height='250px'
                      options={{
                        tooltip: {
                          isHtml: true,
                          text: 'value',
                        },
                        pieSliceText: 'value',
                        pieHole: 0.4,
                        is3D: false,
                        colors: [
                          '#109618',
                          '#990099',
                          '#0099C6',
                          '#DC3912',
                          '#FF9900',
                          '#3366CC',
                          '#DD4477',
                        ],
                      }}
                      data={oemStatusChartData}
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={12} sm={6}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    marginRight: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
                <View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                      color: '#0088CE',
                    }}
                  >
                    Upcoming Maintenance
                  </Text>
                </View>
                <View
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
              </View>
              {reportsLoading && (
                <View
                  pointerEvents='none'
                  style={{
                    paddingTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F5FCFF88',
                    zIndex: 1000,
                  }}
                >
                  <ActivityIndicator size='large' />
                </View>
              )}
              {!reportsLoading && (
                <Row style={{ flex: 1 }}>
                  <Col
                    xs={12}
                    sm={12}
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {hasUpcomingMaintenance && (
                      <Chart
                        chartType='PieChart'
                        width='100%'
                        height='250px'
                        options={{
                          tooltip: {
                            isHtml: true,
                            text: 'value',
                          },
                          pieSliceText: 'value',
                          pieHole: 0.4,
                          is3D: false,
                        }}
                        data={upcomingActionsChartData}
                      />
                    )}
                    {!hasUpcomingMaintenance && (
                      <Text
                        style={{
                          fontSize: '1.8vh',
                          fontWeight: '600',
                          color: '#00205B',
                        }}
                      >
                        No Upcoming Maintenance
                      </Text>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={12} sm={6}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    marginRight: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
                <View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: '1.6vh',
                      fontWeight: '600',
                      color: '#0088CE',
                    }}
                  >
                    Overdue Maintenance
                  </Text>
                </View>
                <View
                  style={{
                    marginLeft: 16,
                    flex: 1,
                    height: 1,
                    backgroundColor: 'black',
                  }}
                />
              </View>
              {reportsLoading && (
                <View
                  pointerEvents='none'
                  style={{
                    paddingTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F5FCFF88',
                    zIndex: 1000,
                  }}
                >
                  <ActivityIndicator size='large' />
                </View>
              )}
              {!reportsLoading && (
                <Row style={{ flex: 1 }}>
                  <Col
                    xs={12}
                    sm={12}
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {hasPastDueMaintenance && (
                      <Chart
                        chartType='PieChart'
                        width='100%'
                        height='250px'
                        options={{
                          tooltip: {
                            isHtml: true,
                            text: 'value',
                          },
                          pieSliceText: 'value',
                          pieHole: 0.4,
                          is3D: false,
                        }}
                        data={overdueActionsChartData}
                      />
                    )}
                    {!hasPastDueMaintenance && (
                      <Text
                        style={{
                          fontSize: '1.8vh',
                          fontWeight: '600',
                          color: '#00205B',
                        }}
                      >
                        No Overdue Maintenance
                      </Text>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4}>
              <View
                style={{
                  margin: 0,
                  width: '100%',
                  boxShadow: '1px 0px 10px grey',
                  overflow: 'hidden',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0.75rem',
                  }}
                >
                  <View
                    style={{
                      marginRight: 16,
                      flex: 1,
                      height: 1,
                      backgroundColor: 'black',
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                        color: '#0088CE',
                      }}
                    >
                      Tool Status
                    </Text>
                  </View>
                  <View
                    style={{
                      marginLeft: 16,
                      flex: 1,
                      height: 1,
                      backgroundColor: 'black',
                    }}
                  />
                </View>
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      paddingTop: '2rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      minHeight: '4rem',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
                {!reportsLoading && (
                  <>
                    {Object.entries(toolsByStatusMap)?.map(([key, value]) => {
                      return (
                        <>
                          <ListItem key={key}>
                            <ListItem.Content>
                              <ListItem.Title>
                                <Text
                                  style={{
                                    fontSize: '1.8vh',
                                    fontWeight: '600',
                                    color: '#00205B',
                                  }}
                                >
                                  {value.name || 'No Facility'} ({value.count})
                                </Text>
                              </ListItem.Title>
                            </ListItem.Content>
                          </ListItem>
                          {Object.entries(
                            toolsByStatusMap[key].locationStatusMap
                          )?.map(([key, value], index) => {
                            return (
                              <ListItem
                                key={index}
                                bottomDivider
                                onPress={() => {
                                  setReportListDataAction(value.tools);
                                  setReportsModalAction(true);
                                }}
                              >
                                <Badge status={value.type} />
                                <ListItem.Content>
                                  <ListItem.Title
                                    style={{
                                      color: '#76787A',
                                      fontSize: '1.4vh',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {key}
                                  </ListItem.Title>
                                </ListItem.Content>
                                <Badge
                                  status={value.type}
                                  value={value.tools?.length}
                                />
                              </ListItem>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                )}
              </View>
            </Col>
            <Col xs={12} sm={4}>
              <View
                style={{
                  margin: 0,
                  width: '100%',
                  boxShadow: '0px 0px 10px grey',
                  overflow: 'hidden',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0.75rem',
                  }}
                >
                  <View
                    style={{
                      marginRight: 16,
                      flex: 1,
                      height: 1,
                      backgroundColor: 'black',
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                        color: '#0088CE',
                      }}
                    >
                      OEM Status
                    </Text>
                  </View>
                  <View
                    style={{
                      marginLeft: 16,
                      flex: 1,
                      height: 1,
                      backgroundColor: 'black',
                    }}
                  />
                </View>
                {reportsLoading && (
                  <View
                    pointerEvents='none'
                    style={{
                      paddingTop: '2rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F5FCFF88',
                      minHeight: '4rem',
                      zIndex: 1000,
                    }}
                  >
                    <ActivityIndicator size='large' />
                  </View>
                )}
                {!reportsLoading && (
                  <>
                    {Object.entries(toolsByOEMStatusMap)?.map(
                      ([key, value]) => {
                        return (
                          <>
                            <ListItem key={key}>
                              <ListItem.Content>
                                <ListItem.Title>
                                  <Text
                                    style={{
                                      fontSize: '1.8vh',
                                      fontWeight: '600',
                                      color: '#00205B',
                                    }}
                                  >
                                    {value.name || 'No Facility'} ({value.count}
                                    )
                                  </Text>
                                </ListItem.Title>
                              </ListItem.Content>
                            </ListItem>
                            {Object.entries(
                              toolsByOEMStatusMap[key].locationStatusMap
                            )?.map(([key, value], index) => {
                              return (
                                <ListItem
                                  key={index}
                                  bottomDivider
                                  onPress={() => {
                                    setReportListDataAction(value.tools);
                                    setReportsModalAction(true);
                                  }}
                                >
                                  <Badge status={value.type} />
                                  <ListItem.Content>
                                    <ListItem.Title
                                      style={{
                                        color: '#76787A',
                                        fontSize: '1.4vh',
                                        fontWeight: '600',
                                      }}
                                    >
                                      {key}
                                    </ListItem.Title>
                                  </ListItem.Content>
                                  <Badge
                                    status={value.type}
                                    value={value.tools?.length}
                                  />
                                </ListItem>
                              );
                            })}
                          </>
                        );
                      }
                    )}
                  </>
                )}
              </View>
            </Col>
            <Col xs={12} sm={4}>
              <View
                style={{
                  margin: 0,
                  width: '100%',
                  boxShadow: '0px 0px 10px grey',
                  overflow: 'hidden',
                  borderRadius: '12px',
                  backgroundColor: 'white',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0.75rem',
                  }}
                >
                  <View
                    style={{
                      marginRight: 16,
                      flex: 1,
                      height: 1,
                      backgroundColor: 'black',
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                        color: '#0088CE',
                      }}
                    >
                      Maintenance
                    </Text>
                  </View>
                  <View
                    style={{
                      marginLeft: 16,
                      flex: 1,
                      height: 1,
                      backgroundColor: 'black',
                    }}
                  />
                </View>
                {Object.entries(reports).map(([key, value]) => {
                  return (
                    <>
                      <ListItem key={key}>
                        <ListItem.Content>
                          <ListItem.Title>
                            <Text
                              style={{
                                fontSize: '1.8vh',
                                fontWeight: '600',
                                color: '#00205B',
                              }}
                            >
                              {value.name}
                            </Text>
                          </ListItem.Title>
                        </ListItem.Content>
                      </ListItem>
                      <ListItem
                        bottomDivider
                        onPress={() => {
                          setReportListDataAction(value.dueTools);
                          setReportsModalAction(true);
                        }}
                      >
                        <Badge status='warning' />
                        <ListItem.Content>
                          <ListItem.Title
                            style={{
                              color: '#76787A',
                              fontSize: '1.4vh',
                              fontWeight: '600',
                            }}
                          >
                            Due Soon
                          </ListItem.Title>
                        </ListItem.Content>
                        <Badge status='warning' value={value?.dueSoon} />
                      </ListItem>
                      <ListItem
                        bottomDivider
                        onPress={() => {
                          setReportListDataAction(value.pastTools);
                          setReportsModalAction(true);
                        }}
                      >
                        <Badge status='error' />
                        <ListItem.Content>
                          <ListItem.Title
                            style={{
                              color: '#76787A',
                              fontSize: '1.4vh',
                              fontWeight: '600',
                            }}
                          >
                            Over Due
                          </ListItem.Title>
                        </ListItem.Content>
                        <Badge status='error' value={value?.pastDue} />
                      </ListItem>
                    </>
                  );
                })}
              </View>
            </Col>
          </Row>
        </View>
      </View>
    </Page.Protected>
  );
}

export default ReportingPage;
