import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import { vhToDp } from '../../utilities/general';

const Container = styled.View`
  flex-direction: ${(props) => (props.fullWidth ? '' : 'row')};
`;

const BaseButton = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${vhToDp(1.5)}px ${vhToDp(3)}px;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
  `}
`;

const PrimaryButton = styled(BaseButton)`
  background-color: #0088ce;
  border: none;
  ${(props) =>
    props.disabled &&
    `
    background-color: #76787a;
  `}
`;

const SecondaryButton = styled(BaseButton)`
  background-color: #00205b;
  border: none;
  ${(props) =>
    props.disabled &&
    `
    background-color: #76787a;
  `}
`;

const TertiaryButton = styled(BaseButton)`
  background-color: #ab2328;
`;

const ButtonText = styled.Text`
  font-size: ${vhToDp(1.6)}px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => {
    return '#ffffff';
  }};
`;

const UnreadBadge = styled.View`
  background-color: #ab2328;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-left: ${vhToDp(1)}px;
`;

const BadgeText = styled.Text`
  color: white;
  font-size: ${vhToDp(1.2)}px;
  font-weight: 600;
`;

const Button = ({
  variant = 'primary',
  children,
  disabled = false,
  fullWidth = false,
  count,
  onPress,
  title = null,
  ...props
}) => {
  const ButtonComponent = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    tertiary: TertiaryButton,
  }[variant];

  return (
    <Container>
      <ButtonComponent
        disabled={disabled}
        fullWidth={fullWidth}
        onPress={!disabled ? onPress : null}
        activeOpacity={0.8}
        {...props}
      >
        <ButtonText variant={variant}>{title ? title : children}</ButtonText>
        {count > 0 && (
          <UnreadBadge>
            <BadgeText>{count}</BadgeText>
          </UnreadBadge>
        )}
      </ButtonComponent>
    </Container>
  );
};

export default Button;

// Compound components for specific button types
Button.Primary = (props) => <Button variant='primary' {...props} />;
Button.Secondary = (props) => <Button variant='secondary' {...props} />;
Button.Tertiary = (props) => <Button variant='tertiary' {...props} />;
