import { useStoreState } from 'easy-peasy';
import Button from '../../containers/Button';
import { View } from 'react-native';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import { ListItem } from '@rneui/themed';
import Text from '../../presentational/Text';

function TenantNavigationList({ navigation, parentId, tenants = [] }) {
  const {
    tenant: { input, initialInput },
    messages: { unreadCount },
    auth: { user },
  } = useStoreState((state) => state);

  const renderActionButton = (ButtonComponent, label, count) => (
    <Col xs={12} md={6}>
      <ButtonComponent
        navigation={navigation}
        unreadCount={count}
        children={label}
        parentId={parentId}
      />
    </Col>
  );

  const actions = [
    { id: 'tools', label: 'Tools', component: Button.GoToTools },
    {
      id: 'messages',
      label: 'Messages',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToMessages,
      count: unreadCount,
    },
    {
      id: 'reporting',
      label: 'Reporting',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToReporting,
    },
    {
      id: 'workPackages',
      label: 'Work Packages',
      component: Button.GoToWorkPackages,
    },
    {
      id: 'brokenTools',
      label: 'Unserviceable Tools',
      component: Button.GoToBrokenTools,
    },
    {
      id: 'transfers',
      label: 'Transfers',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToTransfers,
    },
    {
      id: 'readiness',
      label: 'Readiness',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToReadiness,
    },
  ];

  const navigationItems = [
    {
      id: 'users',
      label: 'Users',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToUsers,
      hideAsChild: false,
    },
    {
      id: 'toolStatus',
      label: 'Tool Status',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToToolStatus,
      hideAsChild: true,
    },
    {
      id: 'toolCategory',
      label: 'Breakage',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToToolCategory,
      hideAsChild: true,
    },
    {
      id: 'locations',
      label: 'Locations',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToLocations,
      hideAsChild: false,
    },
    {
      id: 'storageLocations',
      label: 'Storage Locations',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToStorageLocations,
      hideAsChild: false,
    },
    {
      id: 'inspection',
      label: 'Inspection',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToInspection,
      hideAsChild: false,
    },
    {
      id: 'addTenants',
      label: 'Add Tenant',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.AddTenant,
      hideAsChild: true,
    },
    {
      id: 'region',
      label: 'Region',
      roles: ['admin', 'customer admin', 'Customer Admin'],
      component: Button.GoToRegion,
      hideAsChild: true,
    },
    {
      id: 'engines',
      label: 'Engines',
      component: Button.SelectEngine,
      hideAsChild: false,
    },
  ];

  return (
    <View style={{ flex: 1, height: '100%' }}>
      <ListItem bottomDivider>
        <ListItem.Content>
          <ListItem.Title
            style={{
              color: '#00205B',
              fontSize: '1.8vh',
              fontWeight: '600',
            }}
          >
            Actions
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
      <Row>
        {actions.map((item) => {
          if (item.roles && !item.roles.includes(user.role)) return null;
          return renderActionButton(item.component, item.label, item.count);
        })}
      </Row>
      <ListItem bottomDivider>
        <ListItem.Content>
          <ListItem.Title
            style={{
              color: '#00205B',
              fontSize: '1.8vh',
              fontWeight: '600',
            }}
          >
            Set Up
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
      <Row>
        {navigationItems.map((item) => {
          if (
            (item.roles && !item.roles.includes(user.role)) ||
            (!!initialInput.parentTenantId && item.hideAsChild)
          ) {
            return null;
          }
          return renderActionButton(item.component, item.label, item.count);
        })}
      </Row>
      {!initialInput.parentTenantId && (
        <>
          <ListItem bottomDivider>
            <ListItem.Content>
              <ListItem.Title
                style={{
                  color: '#00205B',
                  fontSize: '1.8vh',
                  fontWeight: '600',
                }}
              >
                Tenants
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
          {tenants?.map((item) => {
            return (
              <ListItem
                onPress={() => navigation.push('Edit Tenant', { id: item.id })}
                style={{
                  boxShadow: '0px 0px 5px grey',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  margin: '8px',
                }}
              >
                <ListItem.Content>
                  <ListItem.Title>
                    <Text
                      style={{
                        color: '#00205B',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                      }}
                    >
                      {item.name}
                    </Text>
                  </ListItem.Title>
                  <ListItem.Subtitle>
                    <Text
                      style={{
                        color: '#76787A',
                        fontSize: '1.4vh',
                        fontWeight: '600',
                      }}
                    >
                      {`${item.userCnt} Users `}&bull;
                      {` ${item.locationCnt} Locations `}&bull;
                    </Text>
                    <Text
                      style={{
                        color: item.active ? 'green' : '#AB2328',
                        fontSize: '1.4vh',
                        fontWeight: '600',
                      }}
                    >{` ${item.active ? 'Active' : 'Inactive'}`}</Text>
                  </ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
            );
          })}
        </>
      )}
    </View>
  );
}

export default TenantNavigationList;
