import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setRegionLoadingAction: action((state, loading) => {
    state.region.loading = loading;
  }),
  setRegionDataAction: action((state, data) => {
    state.region.list = data;
    const options = data.map((item) => {
      return { label: item.regionname, value: item.regionid };
    });
    state.region.dropdownOptions = [{ label: 'None', value: null }, ...options];
  }),
  selectRegionAction: action((state, { index, input }) => {
    state.region.selectedIndex = index;
    state.region.input = input;
  }),
  setRegionInputAction: action((state, input) => {
    state.region.input = {
      ...state.region.input,
      ...input,
    };
  }),
  resetRegionInputAction: action((state, input) => {
    state.region.input = defaultInputState;
    state.region.selectedIndex = null;
  }),
};

export default actions;
