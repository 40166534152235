import { thunk } from 'easy-peasy';
import { getRegionsAPICall, upsertRegionAPICall } from '../../api/region';

const thunks = {
  getRegionsThunk: thunk(async (actions, tenantid, helpers) => {
    actions.setRegionLoadingAction(true);

    try {
      const response = await getRegionsAPICall(tenantid);
      actions.setRegionDataAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setRegionLoadingAction(false);
  }),
  upsertRegionThunk: thunk(async (actions, input, helpers) => {
    actions.setRegionLoadingAction(true);

    try {
      let t = await upsertRegionAPICall(input);
      const response = await getRegionsAPICall(input.tenantid);
      actions.resetRegionInputAction();
      actions.setRegionDataAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setRegionLoadingAction(false);
  }),
};

export default thunks;
