import { Chart } from 'react-google-charts';
import { TouchableOpacity } from 'react-native';

function BarChartGraph({ onPress, dataset = [], height, yAxis, fillColor }) {
  return (
    <TouchableOpacity onPress={onPress}>
      <Chart
        chartType='ColumnChart'
        width='100%'
        height={height}
        data={dataset}
        options={{ vAxis: yAxis }}
      ></Chart>
    </TouchableOpacity>
  );
}

export default BarChartGraph;
