import { useStoreState, useStoreActions } from 'easy-peasy';
import { FlatList } from 'react-native';
import { ListItem } from '@rneui/themed';
import Text from '../../presentational/Text';

function RegionList() {
  const {
    region: { list, selectedIndex },
  } = useStoreState((state) => state);

  const { selectRegionAction } = useStoreActions((actions) => actions);

  return (
    <FlatList
      style={{ overflow: 'auto', height: '100%' }}
      data={list}
      renderItem={({ item, index }) => {
        return (
          <ListItem
            onPress={() => selectRegionAction({ index, input: item })}
            style={{
              borderLeft: selectedIndex === index ? '6px solid #0088CE' : '',
              boxShadow: '0px 0px 5px grey',
              borderRadius: '12px',
              overflow: 'hidden',
              margin: '8px',
            }}
          >
            <ListItem.Content>
              <ListItem.Title>
                <Text
                  style={{
                    color: '#00205B',
                    fontSize: '1.6vh',
                    fontWeight: '600',
                  }}
                >
                  {item.regionname}
                </Text>
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        );
      }}
      keyExtractor={(item) => item.id}
    />
  );
}

//borderLeft: '4px solid #0088CE',

export default RegionList;
