import { thunk } from 'easy-peasy';
import {
  deleteTenantEngineFamilyAPICall,
  getEngineFamilyAPICall,
  getProstEngineFamilyAPICall,
  getTenantEngineFamilyAPICall,
  removeEngineVariantAPICall,
  upsertEngineFamilyAPICall,
  upsertTenantEngineFamilyAPICall,
} from '../../api/engine';

const thunks = {
  getEnginesThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getEngineFamilyAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setEngineListAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertEnginesThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await upsertEngineFamilyAPICall({
        ...helpers.getState().engines.selectedEngine,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Engine Family Saved!',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  removeVariantThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await removeEngineVariantAPICall(item);
      actions.setAlertThunk({
        type: 'success',
        message: 'Engine Variant Removed!',
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  getTenantEnginesThunk: thunk(async (actions, item, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getTenantEngineFamilyAPICall({
        tenantId: helpers.getState().tenant.input.id,
      });
      const prostEngines = await getProstEngineFamilyAPICall();
      actions.setTenantEngineDataAction({
        currentEngines: response.data,
        prostEngines: prostEngines.data,
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertTenantEngineThunk: thunk(async (actions, item, helpers) => {
    try {
      actions.selectTenantEngineOptionAction({
        ...item,
        isSelected: true,
      });
      const response = await upsertTenantEngineFamilyAPICall({
        id: item?.id,
        engineFamilyId: item?.value,
        tenantId: helpers.getState().tenant.input.id,
      });

      actions.selectTenantEngineOptionAction({
        ...item,
        id: response.data[0].id,
        isSelected: true,
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
      actions.selectTenantEngineOptionAction({
        ...item,
        id: null,
        isSelected: false,
      });
    }
  }),
  deleteTenantEngineThunk: thunk(async (actions, item, helpers) => {
    try {
      actions.selectTenantEngineOptionAction({
        ...item,
        isSelected: false,
      });
      const response = await deleteTenantEngineFamilyAPICall({ id: item.id });
      actions.selectTenantEngineOptionAction({
        ...item,
        id: null,
        isSelected: false,
      });
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
      actions.selectTenantEngineOptionAction({
        ...item,
      });
    }
  }),
};

export default thunks;
