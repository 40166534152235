import React from 'react';
import styled from 'styled-components/native';
import { vhToDp } from '../../utilities/general';

const FormS = styled.View`
  padding: ${vhToDp(3)}px;
  background-color: ${(props) => (props.highlight ? '#F8FAFC' : '#ffffff')};
  border-radius: 12px;
  border: 1px solid #edf2f7;
`;

const SectionTitle = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${vhToDp(3)}px;
  padding-bottom: ${vhToDp(1)}px;
  border-bottom-width: 1px;
  border-bottom-color: #edf2f7;
`;

const SectionTitleText = styled.Text`
  font-size: ${vhToDp(1.8)}px;
  font-weight: 600;
  color: #00205b;
`;

const FormSection = ({ title = '', children, highlight }) => {
  return (
    <FormS highlight={highlight}>
      <SectionTitle>
        <SectionTitleText>{title}</SectionTitleText>
      </SectionTitle>
      {children}
    </FormS>
  );
};

export default FormSection;
