import { action } from 'easy-peasy';
import { defaultInputState } from './state';

const actions = {
  setTenantSearchResultsAction: action((state, searchResults) => {
    state.tenant.searchResults = searchResults;
    const options = searchResults.map((item) => {
      return { label: item.name, value: item.id };
    });
    state.tenant.parentDropdownOptions = [
      { label: 'None', value: null },
      ...options,
    ];
  }),
  setTenantSearchInputAction: action((state, searchInput) => {
    state.tenant.searchInput = searchInput;
  }),
  setIsEditableTenantAction: action((state, isEditable) => {
    state.tenant.isEditable = isEditable;
  }),
  setTenantChildTenants: action((state, list) => {
    state.tenant.tenants = list;
  }),
  setTenantInputAction: action((state, input) => {
    state.tenant.input = {
      ...state.tenant.input,
      ...input,
    };
  }),
  setInitialTenantInputAction: action((state, input) => {
    state.tenant.initialInput = {
      ...input,
    };
  }),
  resetTenantInputAction: action((state, input) => {
    state.tenant.input = defaultInputState;
  }),

  setTenantLabels: action((state, searchResults) => {
    state.tenant.labels = searchResults;
  }),
  setLabelSearchInputAction: action((state, searchInput) => {
    state.tenant.labelSearchInput = searchInput;
  }),
  setActiveFilterAction: action((state, filter) => {
    state.tenant.activeFilter = filter;
  }),
};

export default actions;
