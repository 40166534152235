import { useStoreState, useStoreActions } from 'easy-peasy';
import { useIsFocused } from '@react-navigation/native';
import LoginPage from './LoginPage';
import Page from './Page';
import { useEffect } from 'react';

function ProtectedPage({ children, ...props }) {
  const { firstName, tenantId } = useStoreState((state) => state.auth.user);

  const isFocused = useIsFocused();

  const { getAuthUserThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getAuthUserThunk();
  }, [isFocused]);

  return <Page {...props}>{tenantId ? children : <LoginPage />}</Page>;
}

export default ProtectedPage;
