import Page from '../containers/Page';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Form from '../containers/Form';
import List from '../containers/List';

function RegionPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();
  const {
    tenant,
    region: { loading },
  } = useStoreState((state) => state);

  const { getRegionsThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    const loadData = async () => {
      await getRegionsThunk(tenant.input.id);
    };
    if (isFocused) {
      loadData();
    }
  }, [isFocused, route.params]);

  return (
    <Page.Default
      title='Regions'
      loading={loading}
      navBar={
        <Row style={{ flex: 1, height: '100%' }}>
          <Col xs={12} md={12} style={{ flex: 1, height: '100%' }}>
            <List.Regions />
          </Col>
        </Row>
      }
      body={
        <Row style={{ flex: 1, height: '100%' }}>
          <Form.Region />
        </Row>
      }
    ></Page.Default>
  );
}

export default RegionPage;
