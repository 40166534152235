import { createStore } from 'easy-peasy';
import statusStore from './status';
import authStore from './auth';
import tenantStore from './tenant';
import regionStore from './region';
import usersStore from './users';
import toolsStore from './tools';
import locationsStore from './location';
import documentStore from './documents';
import dropdownStore from './dropdowns';
import rolesStore from './roles';
import labelStore from './labels';
import workPackageStore from './workPackage';
import workPackageToolStore from './workPackageToolList';
import inspectionStore from './inspectionTypes';
import inspectionsStore from './inspections';
import inspectionItemStore from './inspectionItems';
import toolStatusStore from './toolStatus';
import storageLocationStore from './storageLocation';
import messageStore from './message';
import readinessStore from './readinessScorecard';
import engineStore from './engine';

const store = createStore({
  ...statusStore,
  ...authStore,
  ...tenantStore,
  ...regionStore,
  ...usersStore,
  ...toolsStore,
  ...locationsStore,
  ...documentStore,
  ...rolesStore,
  ...dropdownStore,
  ...labelStore,
  ...workPackageStore,
  ...workPackageToolStore,
  ...inspectionStore,
  ...inspectionItemStore,
  ...toolStatusStore,
  ...storageLocationStore,
  ...messageStore,
  ...inspectionsStore,
  ...readinessStore,
  ...engineStore,
});

export default store;
