import Page from '../containers/Page';
import { useIsFocused } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import AddTenantForm from '../containers/Form/AddTenantForm';
import Button from '../presentational/Button';

function AddTenantPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();

  const { input } = useStoreState((state) => state.tenant);
  const { resetTenantInputAction, resetDocumentAction, upsertTenantThunk } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    if (isFocused) {
      resetDocumentAction();
      resetTenantInputAction();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <AddTenantForm
        editable={true}
        buttons={[
          <Button
            onPress={async () => {
              await upsertTenantThunk({
                navigation,
                input: { ...input, parentTenantId: route.params.parentId },
                message: 'Added tenant',
              });
              navigation.navigate('Edit Tenant', { id: route.params.parentId });
            }}
            disabled={!input.name}
            fullWidth
          >
            Save
          </Button>,
        ]}
      />
    </Page.Protected>
  );
}

export default AddTenantPage;
