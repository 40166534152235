import { axios } from '.';

const regionAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/region`,
});

function getRegionsAPICall(tenantid) {
  return regionAxios({ method: 'get', url: `/list/get/${tenantid}` });
}

function upsertRegionAPICall(data) {
  return regionAxios({ method: 'put', url: `/upsert`, data });
}

export { regionAxios, getRegionsAPICall, upsertRegionAPICall };
