import { useStoreActions, useStoreState } from 'easy-peasy';
import Switch from '../../presentational/Switch';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { vhToDp } from '../../../utilities/general';
import FormSection from '../../presentational/FormSection';
import UniversalTextInput from '../../presentational/TextInput';
import { ScrollView } from 'react-native-gesture-handler';
import StylishSelect from '../../presentational/Select';

const FormContainer = styled(View)`
  flex: 1;
  height: 100%;
`;

const FormHeader = styled.View`
  flex-direction: row;
  padding: ${vhToDp(3)}px;
  border-bottom-width: 1px;
  border-bottom-color: #edf2f7;
  width: 100%;
  justify-content: space-between;
`;

const FormTitle = styled.Text`
  font-size: ${vhToDp(2.4)}px;
  font-weight: 700;
  color: #00205b;
`;

const FormSubtitle = styled.Text`
  font-size: ${vhToDp(1.4)}px;
  color: #6b7280;
  margin-top: ${vhToDp(0.5)}px;
`;

function AddTenantForm({
  buttons,
  title = 'Add Tenant',
  message = 'Add tenant information and settings',
  showParent = false,
  showRegion = false,
  editable,
  ...props
}) {
  const {
    tenant: { input, parentDropdownOptions },
    region: { dropdownOptions },
    document,
    status: { placeholder },
  } = useStoreState((state) => state);

  const { setTenantInputAction, getRegionsThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <FormContainer>
      <FormHeader>
        <View>
          <FormTitle>{title}</FormTitle>
          <FormSubtitle>{message}</FormSubtitle>
        </View>
        <Row
          style={{
            margin: 5,
          }}
        >
          {buttons.map((button, index) => button)}
        </Row>
      </FormHeader>
      <ScrollView>
        <Row>
          <Col xs={12} md={6}>
            <FormSection title={'Basic Information'}>
              <UniversalTextInput
                placeholder='Type in tenant name'
                label='Name'
                value={input.name}
                onChangeText={(name) => setTenantInputAction({ name })}
                required
                editable={editable}
              />
              <UniversalTextInput
                placeholder='Type in billing reference'
                label='Billing Reference'
                value={input.billingReference}
                onChangeText={(billingReference) =>
                  setTenantInputAction({ billingReference })
                }
                editable={editable}
              />
              {showParent && (
                <StylishSelect
                  searchable={false}
                  options={parentDropdownOptions}
                  value={input.parentTenantId}
                  onChange={async (parentTenantId) => {
                    setTenantInputAction({ parentTenantId, regionId: null });
                    if (parentTenantId) {
                      await getRegionsThunk(parentTenantId);
                    }
                  }}
                  label='Parent'
                ></StylishSelect>
              )}
              {showRegion && (
                <StylishSelect
                  searchable={false}
                  options={dropdownOptions}
                  value={input.regionId}
                  onChange={(regionId) => setTenantInputAction({ regionId })}
                  label='Region'
                ></StylishSelect>
              )}
            </FormSection>
          </Col>
          <Col xs={12} md={6}>
            <FormSection title='Contact Information'>
              <UniversalTextInput
                placeholder='Type in Rhinestahl Contact'
                label='Rhinestahl Contact'
                value={input.rhinestahlEmailContact}
                onChangeText={(rhinestahlEmailContact) =>
                  setTenantInputAction({ rhinestahlEmailContact })
                }
                editable={editable}
              />
              <UniversalTextInput
                placeholder='Type in Quality Control Email Contact'
                label='Quality Control Email Contact'
                value={input.qualitycontrolEmailContact}
                onChangeText={(qualitycontrolEmailContact) =>
                  setTenantInputAction({ qualitycontrolEmailContact })
                }
                editable={editable}
              />
            </FormSection>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <FormSection title='Location Details'>
              <Row>
                <Col xs={12} md={6}>
                  <UniversalTextInput
                    placeholder='Type in address 1'
                    label='Address 1'
                    value={input.address1}
                    onChangeText={(address1) =>
                      setTenantInputAction({ address1 })
                    }
                    editable={editable}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <UniversalTextInput
                    placeholder='Type in address 2'
                    label='Address 2'
                    value={input.address2}
                    onChangeText={(address2) =>
                      setTenantInputAction({ address2 })
                    }
                    editable={editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <UniversalTextInput
                    placeholder='Type in city'
                    label='City'
                    value={input.city}
                    onChangeText={(city) => setTenantInputAction({ city })}
                    editable={editable}
                  />
                </Col>
                <Col xs={12} xl={4}>
                  <UniversalTextInput
                    placeholder='Type in state / province'
                    label='State / Province'
                    value={input.stateProvince}
                    onChangeText={(stateProvince) =>
                      setTenantInputAction({ stateProvince })
                    }
                    editable={editable}
                  />
                </Col>
                <Col xs={12} xl={4}>
                  <UniversalTextInput
                    placeholder='Type in Zip / Postal'
                    label='Zip / Postal'
                    value={input.zipPostal}
                    onChangeText={(zipPostal) =>
                      setTenantInputAction({ zipPostal })
                    }
                    editable={editable}
                  />
                </Col>
              </Row>
            </FormSection>
          </Col>
          <Col xs={12} md={4}>
            <FormSection title='Status'>
              <Switch
                label='Active'
                value={input.active}
                onValueChange={(active) => setTenantInputAction({ active })}
                color='#0088CE'
                editable={editable}
              />
            </FormSection>
          </Col>
        </Row>
      </ScrollView>
    </FormContainer>
  );
}

export default AddTenantForm;
