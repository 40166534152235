import Button from '../../presentational/Button';

function useOnPress(navigation, parentId) {
  return () => {
    navigation.navigate('Add Tenant', { parentId });
  };
}

export default function GoToAddTenantButton({
  navigation,
  parentId,
  children = 'Add Tenant',
  ...props
}) {
  const onPress = useOnPress(navigation, parentId);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
