import { thunk } from 'easy-peasy';
import {
  getTenantAPICall,
  getTenantsAPICall,
  upsertTenantAPICall,
} from '../../api/tenant';
import { loadDocumentAPICall, upsertDocumentAPICall } from '../../api/document';
import { getRegionsAPICall } from '../../api/region';

const thunks = {
  getTenantsThunk: thunk(async (actions, _, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getTenantsAPICall(0);
      actions.setTenantSearchResultsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getTenantThunk: thunk(async (actions, id, helpers) => {
    actions.resetDocumentAction();
    try {
      const tenantResponse = await getTenantsAPICall(id);

      if (tenantResponse.data[0].parentTenantId) {
        const response = await getRegionsAPICall(
          tenantResponse.data[0].parentTenantId
        );
        actions.setRegionDataAction(response.data);
      }

      if (tenantResponse.data[0].repositoryId !== null) {
        const documentResponse = await loadDocumentAPICall({
          tenantId: parseInt(tenantResponse.data[0].id),
          id: tenantResponse.data[0].repositoryId,
        });

        if (documentResponse.data.length > 0) {
          actions.setDocumentAction(documentResponse.data[0]);
        } else {
          actions.setDocumentAction({
            ...helpers.getState().document,
            body: helpers.getState().status.placeholder,
          });
        }
      } else {
        actions.setDocumentAction({
          ...helpers.getState().document,
          body: helpers.getState().status.placeholder,
        });
      }
      actions.setTenantInputAction(tenantResponse.data[0]);
      actions.setInitialTenantInputAction(tenantResponse.data[0]);
      actions.setTenantChildTenants(
        tenantResponse.data.filter((tenant) => !!tenant.parentTenantId)
      );
    } catch (err) {
      console.log('err', err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  upsertTenantThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        let t = await upsertTenantAPICall({
          ...input,
          repositoryId: 0,
        });

        // const res = await upsertDocumentAPICall({
        //   ...helpers.getState().document,
        //   tenantId: t.data.id,
        // });

        // await upsertTenantAPICall({
        //   ...input,
        //   id: t.data.id,
        //   repositoryId: res.data.id,
        // });

        actions.setTenantInputAction(t.data);
        actions.setInitialTenantInputAction(t.data);

        actions.setAlertThunk({
          type: 'success',
          message,
        });
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
