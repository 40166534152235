import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Animated,
  ScrollView,
  StyleSheet,
  Modal,
  Platform,
  Pressable,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import UniversalTextInput from './TextInput';
import { vhToDp } from '../../utilities/general';

const StylishSelect = ({
  options = [],
  value,
  onChange,
  placeholder = 'Select an option',
  label,
  error,
  disabled,
  searchable = true,
  multiple = false,
  required = false,
  editable = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(
    multiple ? (Array.isArray(value) ? value : []) : value
  );
  const animation = useRef(new Animated.Value(0)).current;
  const [animatedIsFocused] = useState(new Animated.Value(value ? 1 : 0));

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (multiple) {
      setSelectedOptions(Array.isArray(value) ? value : []);
    } else {
      setSelectedOptions(value);
    }
  }, [value, multiple]);

  const toggleSelect = () => {
    if (!editable || disabled) return;

    setIsOpen(!isOpen);
    Animated.spring(animation, {
      toValue: isOpen ? 0 : 1,
      useNativeDriver: true,
      tension: 20,
      friction: 5,
    }).start();
  };

  const handleSelect = (option) => {
    if (multiple) {
      const newSelection = selectedOptions.includes(option.value)
        ? selectedOptions.filter((item) => item !== option.value)
        : [...selectedOptions, option.value];

      setSelectedOptions(newSelection);
      onChange(newSelection);
    } else {
      setSelectedOptions(option.value);
      onChange(option.value);
      setIsOpen(false);
    }
  };

  const renderValue = () => {
    if (
      multiple &&
      Array.isArray(selectedOptions) &&
      selectedOptions.length > 0
    ) {
      const selectedLabels = options
        .filter((option) => selectedOptions.includes(option.value))
        .map((option) => option.label);
      return selectedLabels.join(', ');
    }

    const selectedOption = options.find(
      (option) => option.value === selectedOptions
    );
    return selectedOption ? selectedOption.label : placeholder;
  };

  const rotate = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const labelStyle = {
    position: 'absolute',
    left: 0,
    top: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [18, 0],
    }),
    fontSize: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [16, 14],
    }),
    color: animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: ['#9CA3AF', '#374151'],
    }),
  };

  return (
    <View style={styles.container}>
      {label && (
        <View style={styles.labelContainer}>
          <Text style={[styles.label, labelStyle]}>{label}</Text>
          {required && <Text style={styles.required}>*</Text>}
        </View>
      )}

      <TouchableOpacity
        onPress={toggleSelect}
        activeOpacity={0.7}
        style={[
          styles.selectButton,
          error && styles.errorBorder,
          disabled && styles.disabled,
          !editable && styles.disabled,
        ]}
      >
        <Text
          style={[
            styles.selectText,
            (!selectedOptions || selectedOptions.length === 0) &&
              styles.placeholder,
          ]}
          numberOfLines={1}
        >
          {renderValue()}
        </Text>
        <Animated.View style={{ transform: [{ rotate }] }}>
          <Ionicons name='chevron-down' size={20} color='#6B7280' />
        </Animated.View>
      </TouchableOpacity>

      <Modal
        visible={isOpen}
        transparent
        animationType='fade'
        onRequestClose={() => setIsOpen(false)}
      >
        <Pressable style={styles.modalOverlay} onPress={() => setIsOpen(false)}>
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              {searchable && (
                <View style={styles.searchContainer}>
                  <Ionicons
                    name='search'
                    size={20}
                    color='#6B7280'
                    style={styles.searchIcon}
                  />
                  <UniversalTextInput
                    style={styles.searchInput}
                    placeholder='Search...'
                    value={searchQuery}
                    onChangeText={setSearchQuery}
                    autoFocus
                  />
                </View>
              )}
            </View>

            <ScrollView style={styles.optionsList}>
              {filteredOptions.map((option) => (
                <TouchableOpacity
                  key={option.value}
                  style={styles.option}
                  onPress={() => handleSelect(option)}
                  activeOpacity={0.7}
                >
                  {multiple && (
                    <View
                      style={[
                        styles.checkbox,
                        selectedOptions.includes(option.value) &&
                          styles.checkboxSelected,
                      ]}
                    >
                      {selectedOptions.includes(option.value) && (
                        <Ionicons name='checkmark' size={16} color='#ffffff' />
                      )}
                    </View>
                  )}
                  <Text
                    style={[
                      styles.optionText,
                      selectedOptions === option.value && styles.selectedOption,
                    ]}
                  >
                    {option.label}
                  </Text>
                  {!multiple && selectedOptions === option.value && (
                    <Ionicons name='checkmark' size={20} color='#0088CE' />
                  )}
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        </Pressable>
      </Modal>

      {error && <Text style={styles.errorText}>{error}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: vhToDp(1.25),
  },
  label: {
    fontSize: 14,
    fontWeight: '500',
    color: '#374151',
  },
  required: {
    color: '#DC2626',
    fontSize: 14,
  },
  selectButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    borderWidth: 1,
    borderColor: '#E5E7EB',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    minHeight: 48,
    ...Platform.select({
      web: {
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        ':hover': {
          borderColor: '#0088CE',
        },
      },
    }),
  },
  selectText: {
    fontSize: 16,
    color: '#1F2937',
    flex: 1,
    marginRight: 8,
  },
  placeholder: {
    color: '#9CA3AF',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  modalContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    overflow: 'hidden',
    width: '100%',
    maxWidth: 400,
    maxHeight: '80%',
    ...Platform.select({
      web: {
        boxShadow:
          '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      },
      default: {
        elevation: 5,
      },
    }),
  },
  modalHeader: {
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
    paddingLeft: 8,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F9FAFB',
    borderRadius: 8,
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
    color: '#1F2937',
    padding: 0,
    ...Platform.select({
      web: {
        outline: 'none',
      },
    }),
  },
  optionsList: {
    maxHeight: 300,
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#F3F4F6',
  },
  optionText: {
    fontSize: 16,
    color: '#1F2937',
    flex: 1,
  },
  selectedOption: {
    color: '#0088CE',
    fontWeight: '500',
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 2,
    borderColor: '#D1D5DB',
    borderRadius: 4,
    marginRight: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxSelected: {
    backgroundColor: '#0088CE',
    borderColor: '#0088CE',
  },
  errorBorder: {
    borderColor: '#DC2626',
  },
  errorText: {
    color: '#DC2626',
    fontSize: 14,
    marginTop: 4,
  },
  disabled: {
    backgroundColor: '#F3F4F6',
    opacity: 0.7,
    ...Platform.select({
      web: {
        cursor: 'not-allowed',
      },
    }),
  },
});

export default StylishSelect;
