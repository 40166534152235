import Page from '../containers/Page';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Fragment, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import Text from '../presentational/Text';

import { View } from 'react-native';
import TenantNavigationList from '../containers/List/TenantNavigationList';
import AddTenantForm from '../containers/Form/AddTenantForm';
import Button from '../presentational/Button';
import { useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import CustomModal from '../presentational/Modal';

function EditTenantPage({ route, navigation, ...props }) {
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);
  const {
    tenant: { searchResults, tenants, input, initialInput },
    auth: { user },
  } = useStoreState((state) => state);

  const {
    getTenantThunk,
    setIsEditableTenantAction,
    getMessagesThunk,
    setLoadingAction,
    upsertTenantThunk,
    getTenantsThunk,
    getTenantEnginesThunk,
  } = useStoreActions((actions) => actions);

  useEffect(() => {
    const loadData = async () => {
      await setLoadingAction(true);
      await getTenantsThunk();
      await getTenantThunk(route.params.id);
      await getTenantEnginesThunk();
      await setIsEditableTenantAction(false);
      await getMessagesThunk();
      await setLoadingAction(false);
      setLoading(false);
    };
    if (isFocused) {
      loadData();
    }
  }, [isFocused, route.params]);

  return (
    <Fragment>
      <CustomModal.EngineSelect />
      <Page.Default
        title={input?.name}
        loading={loading}
        navBar={
          <Row style={{ flex: 1, height: '100%' }}>
            <Col xs={12} md={12} style={{ flex: 1, height: '100%' }}>
              <ScrollView
                style={{ flex: 1 }}
                contentContainerStyle={{ flexGrow: 1 }}
              >
                <TenantNavigationList
                  parentId={route.params.id}
                  navigation={navigation}
                  tenants={tenants}
                />
              </ScrollView>
            </Col>
          </Row>
        }
        body={
          <Fragment>
            <Row>
              <Col xs={12} md={3}>
                <View
                  style={{
                    padding: '16px',
                    borderLeft: '4px solid #0088CE',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 5px grey',
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: '#0088CE',
                        fontSize: '1.8vh',
                        fontWeight: '600',
                      }}
                    >
                      {searchResults
                        .filter((x) => x.id === input.id)
                        .reduce((partialSum, a) => partialSum + a.userCnt, 0)}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        color: '#76787A',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                      }}
                    >
                      Total Users
                    </Text>
                  </div>
                </View>
              </Col>
              <Col xs={12} md={3}>
                <View
                  style={{
                    padding: '16px',
                    borderLeft: '4px solid #0088CE',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 5px grey',
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: '#0088CE',
                        fontSize: '1.8vh',
                        fontWeight: '600',
                      }}
                    >
                      {searchResults
                        .filter((x) => x.id === input.id)
                        .reduce((partialSum, a) => partialSum + a.tenantCnt, 0)}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        color: '#76787A',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                      }}
                    >
                      Tenants
                    </Text>
                  </div>
                </View>
              </Col>
              <Col xs={12} md={3}>
                <View
                  style={{
                    padding: '16px',
                    borderLeft: '4px solid #0088CE',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 5px grey',
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: '#0088CE',
                        fontSize: '1.8vh',
                        fontWeight: '600',
                      }}
                    >
                      {searchResults
                        .filter((x) => x.id === input.id)
                        .reduce(
                          (partialSum, a) => partialSum + a.locationCnt,
                          0
                        )}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        color: '#76787A',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                      }}
                    >
                      Locations
                    </Text>
                  </div>
                </View>
              </Col>
              <Col xs={12} md={3}>
                <View
                  style={{
                    padding: '16px',
                    borderLeft: '4px solid #0088CE',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 5px grey',
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: '#0088CE',
                        fontSize: '1.8vh',
                        fontWeight: '600',
                      }}
                    >
                      {searchResults.filter((x) => x.id === input.id)[0]
                        ?.sysupTime || '99.97%'}
                    </Text>
                  </div>
                  <div>
                    <Text
                      style={{
                        color: '#76787A',
                        fontSize: '1.6vh',
                        fontWeight: '600',
                      }}
                    >
                      System Uptime
                    </Text>
                  </div>
                </View>
              </Col>
            </Row>
            <Row style={{ flex: 1, height: '100%' }}>
              <AddTenantForm
                title='Edit Tenant'
                message='Edit tenant information and settings'
                showParent
                showRegion={!!initialInput?.parentTenantId}
                editable={
                  user.role.toLowerCase() === 'admin' ||
                  user.role.toLowerCase() === 'customer admin'
                }
                buttons={[
                  <Button
                    onPress={async () => {
                      setLoading(true);
                      await upsertTenantThunk({
                        navigation,
                        input: input,
                        message: 'updated tenant',
                      });
                      await getTenantThunk(input.id);
                      setLoading(false);
                    }}
                    disabled={!input.name}
                    fullWidth
                  >
                    Save
                  </Button>,
                ]}
              />
            </Row>
          </Fragment>
        }
      ></Page.Default>
    </Fragment>
  );
}

export default EditTenantPage;
