import { NavigationContainer } from '@react-navigation/native';
import Home from '../pages/Home';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import EditTenantPage from '../pages/EditTenantPage';
import { useStoreState } from 'easy-peasy';
import DrawerC from '../containers/Drawer';
import AddTenantPage from '../pages/AddTenantPage';
import UsersPage from '../pages/UsersPage';
import AddUserPage from '../pages/AddUserPage';
import ToolPage from '../pages/ToolPage';
import AddToolPage from '../pages/AddToolPage';
import ManageConfigPage from '../pages/ManageERPConfigPage';
import EditUserPage from '../pages/EditUserPage';
import AddLocationPage from '../pages/AddLocationPage';
import LocationsPage from '../pages/LocationsPage';
import EditLocationsPage from '../pages/EditLocationsPage';
import EditToolPage from '../pages/EditToolPage';
import TenantLabelPage from '../pages/TenantLabelPage';
import RolesPage from '../pages/RolesPage';
import AddLabelPage from '../pages/AddLabelPage';
import DropdownsPage from '../pages/DropdownsPage';
import AddDropdownPage from '../pages/AddDropdownPage';
import EditLabelPage from '../pages/EditLabelPage';
import EditDropdownPage from '../pages/EditDropdownPage';
import WorkPackagesPage from '../pages/WorkPackagesPage';
import AddWorkPackagePage from '../pages/AddWorkPackagePage';
import EditWorkPackagePage from '../pages/EditWorkPackagePage';
import EditWorkPackageToolPage from '../pages/EditWorkPackageToolPage';
import AddWorkPackageToolPage from '../pages/AddWorkPackageToolPage';
import WorkPackageToolsPage from '../pages/WorkPackagesToolPage';
import AddDuplicateWorkPackagePage from '../pages/AddDuplicateWorkPackagePage';
import InspectionPage from '../pages/InspectionPage';
import InspectionItemPage from '../pages/InspectionItemsPage';
import AddInspectionTypesPage from '../pages/AddInspectionTypePage';
import EditInspectionTypesPage from '../pages/EditInspectionTypePage';
import AddInspectionItemsPage from '../pages/AddInspectionItemPage';
import EditInspectionItemsPage from '../pages/EditInspectionItemPage';
import NonAdminHomePage from '../pages/NonAdminHomePage';
import ToolStatusPage from '../pages/ToolStatusPage';
import AddToolStatusPage from '../pages/AddToolStatusPage';
import EditToolStatusPage from '../pages/EditToolStatusPage';
import StorageLocationsPage from '../pages/StorageLocationsPage';
import AddStorageLocationPage from '../pages/AddStorageLocationPage';
import BrokenToolPage from '../pages/BrokenToolPage';
import EditBrokenToolPage from '../pages/EditBrokenToolPage';
import AddBrokenToolPage from '../pages/AddBrokenToolPage';
import ViewBrokenToolReportPage from '../pages/ViewBrokenToolPage';
import { Platform } from 'react-native';
import { vhToDp } from '../../utilities/general';
import Image from '../presentational/Image';
import EditStorageLocationPage from '../pages/EditStorageLocationPage';
import React from 'react';
import ToolCategoryPage from '../pages/ToolCategoryPage';
import AddToolCategoryPage from '../pages/AddToolCategoryPage';
import EditToolCategoryPage from '../pages/EditToolCategoryPage';
import OEMStatusPage from '../pages/OEMStatusPage';
import AddOEMStatusPage from '../pages/AddOEMStatusPage';
import EditOEMStatusPage from '../pages/EditOEMStatusPage';
import WorkPackageReservationsPage from '../pages/WorkPackageReservationPage';
import MessageCenterPage from '../pages/MessageCenterPage';
import ToolTransferPage from '../pages/ToolTransferPage';
import ReportingPage from '../pages/ReportingPage';
import ReadinessScorePage from '../pages/ReadinessScorePage';
import AddReadinessScorePage from '../pages/AddReadinessScorePage';
import EngineFamilyListPage from '../pages/EngineFamilyList';
import UpsertEngineFamilyPage from '../pages/UpsertEngineFamily';
import RegionPage from '../pages/RegionPage';

const Stack = createStackNavigator();
const DrawerN = createDrawerNavigator();

function MyDrawer() {
  const {
    auth: {
      user: { firstName, role, tenantId },
    },
    tools: { myToolFilter },
  } = useStoreState((state) => state);

  return (
    <DrawerN.Navigator
      screenOptions={{
        headerRight: () => <DrawerC.Header />,
        headerTitleStyle: {
          fontFamily: 'Barlow_600SemiBold',
        },
      }}
      drawerContent={({ state, navigation }) => (
        <DrawerC.Content state={state} navigation={navigation} />
      )}
    >
      {role == 'admin' ? (
        <DrawerN.Screen
          name='Home / Tenants'
          component={Home}
          options={{
            title: 'Tenants',
            headerShown: true,
          }}
        />
      ) : role == 'Customer Admin' ||
        role == 'customer admin' ||
        role == 'Customer Planner' ? (
        <DrawerN.Screen
          name='Tenant'
          component={EditTenantPage}
          initialParams={{ id: tenantId }}
          options={{
            headerShown: true,
          }}
        />
      ) : (
        <DrawerN.Screen
          name={!myToolFilter ? 'Tools' : 'Work Packages'}
          component={NonAdminHomePage}
          options={{
            headerShown: true,
          }}
        />
      )}
    </DrawerN.Navigator>
  );
}

function AppNavigator() {
  const { firstName } = useStoreState((state) => state.auth.user);

  return (
    <NavigationContainer>
      <Stack.Navigator
        mode='card'
        screenOptions={{
          cardStyle: { flex: 1 },
          layout: {
            screenOrientation: 'landscape',
          },
          headerStyle: {
            height: '6vh',
          },
          headerRight: () => <DrawerC.Header />,
          headerTitleStyle: {
            fontFamily: 'Barlow_600SemiBold',
          },
        }}
      >
        <Stack.Screen
          name='Home'
          component={MyDrawer}
          options={{ headerShown: false }}
        />
        <Stack.Screen name='Users' component={UsersPage} />
        <Stack.Screen name='Locations' component={LocationsPage} />
        <Stack.Screen name='Tools' component={ToolPage} />
        <Stack.Screen name='Add Location' component={AddLocationPage} />
        <Stack.Screen name='Edit Location' component={EditLocationsPage} />
        <Stack.Screen name='Add Tool' component={AddToolPage} />
        <Stack.Screen name='Edit Tool' component={EditToolPage} />
        <Stack.Screen name='Add User' component={AddUserPage} />
        <Stack.Screen name='Edit User' component={EditUserPage} />
        <Stack.Screen name='Labels' component={TenantLabelPage} />
        <Stack.Screen name='Add Label' component={AddLabelPage} />
        <Stack.Screen name='Config' component={EditLabelPage} />
        <Stack.Screen name='Roles' component={RolesPage} />
        <Stack.Screen name='Dropdowns' component={DropdownsPage} />
        <Stack.Screen name='Add Dropdown' component={AddDropdownPage} />
        <Stack.Screen name='Work Packages' component={WorkPackagesPage} />
        <Stack.Screen name='Add Work Package' component={AddWorkPackagePage} />
        <Stack.Screen
          name='Edit Work Package'
          component={EditWorkPackagePage}
        />
        <Stack.Screen
          name='Work Package Tools'
          component={WorkPackageToolsPage}
        />
        <Stack.Screen
          name='Add Work Package Tool'
          component={AddWorkPackageToolPage}
        />
        <Stack.Screen
          name='Edit Work Package Tool'
          component={EditWorkPackageToolPage}
        />
        <Stack.Screen name='Edit Dropdown' component={EditDropdownPage} />
        <Stack.Screen
          name='Edit Tenant'
          component={EditTenantPage}
          initialParams={{ id: 0 }}
        />
        <Stack.Screen name='Region' component={RegionPage} />
        <Stack.Screen name='Add Tenant' component={AddTenantPage} />
        <Stack.Screen
          name='Add Duplicate Work Package'
          component={AddDuplicateWorkPackagePage}
        />
        <Stack.Screen name='Inspection' component={InspectionPage} />
        <Stack.Screen name='Inspection Items' component={InspectionItemPage} />
        <Stack.Screen
          name='Add Inspection Type'
          component={AddInspectionTypesPage}
        />
        <Stack.Screen
          name='Add Inspection Item'
          component={AddInspectionItemsPage}
        />
        <Stack.Screen
          name='Edit Inspection Type'
          component={EditInspectionTypesPage}
        />
        <Stack.Screen
          name='Edit Inspection Item'
          component={EditInspectionItemsPage}
        />
        <Stack.Screen name='Tool Status' component={ToolStatusPage} />
        <Stack.Screen name='Add Tool Status' component={AddToolStatusPage} />
        <Stack.Screen name='Edit Tool Status' component={EditToolStatusPage} />
        <Stack.Screen
          name='Storage Locations'
          component={StorageLocationsPage}
        />
        <Stack.Screen
          name='Add Storage Location'
          component={AddStorageLocationPage}
        />
        <Stack.Screen
          name='Edit Storage Location'
          component={EditStorageLocationPage}
        />
        <Stack.Screen name='Unserviceable Tools' component={BrokenToolPage} />
        <Stack.Screen
          name='Add Unserviceable Tool'
          component={AddBrokenToolPage}
        />
        <Stack.Screen
          name='Edit Unserviceable Tool'
          component={EditBrokenToolPage}
        />
        <Stack.Screen
          name='Broken Tool Report'
          component={ViewBrokenToolReportPage}
        />

        <Stack.Screen name='Breakage Category' component={ToolCategoryPage} />
        <Stack.Screen
          name='Add Breakage Category'
          component={AddToolCategoryPage}
        />
        <Stack.Screen
          name='Edit Breakage Category'
          component={EditToolCategoryPage}
        />
        <Stack.Screen name='Message Center' component={MessageCenterPage} />
        <Stack.Screen name='Tool Transfers' component={ToolTransferPage} />
        <Stack.Screen name='Reporting' component={ReportingPage} />
        <Stack.Screen name='Readiness Score' component={ReadinessScorePage} />
        <Stack.Screen
          name='Add Readiness Score'
          component={AddReadinessScorePage}
        />

        <Stack.Screen name='OEM Status' component={OEMStatusPage} />
        <Stack.Screen name='Add OEM Status' component={AddOEMStatusPage} />
        <Stack.Screen name='Edit OEM Status' component={EditOEMStatusPage} />

        <Stack.Screen
          name='Work Package Reservations'
          component={WorkPackageReservationsPage}
        />

        <Stack.Screen name='Engine Family' component={EngineFamilyListPage} />
        <Stack.Screen
          name='Add Engine Family'
          component={UpsertEngineFamilyPage}
        />
        <Stack.Screen
          name='Edit Engine Family'
          component={UpsertEngineFamilyPage}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppNavigator;
