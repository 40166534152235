const defaultInputState = {
  regionid: null,
  tenantid: null,
  regionname: '',
  regiontype: '',
  regioncode: '',
};

const state = {
  region: {
    list: [],
    input: {
      regionid: null,
      tenantid: null,
      regionname: null,
      regiontype: null,
      regioncode: null,
    },
    loading: false,
    dropdownOptions: [],
    selectedIndex: null,
  },
};

export { state as default, defaultInputState };
