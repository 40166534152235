import Button from '../../presentational/Button';

function useOnPress(navigation) {
  return () => {
    navigation.navigate('Region');
  };
}

export default function GoToRegionButton({
  navigation,
  children = 'Region',
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <Button onPress={onPress} fullWidth {...props}>
      {children}
    </Button>
  );
}
