import moment from 'moment';
import List from '../../presentational/List';
import { TouchableOpacity } from 'react-native';
import Text from '../../presentational/Text';
import ToolCancelledTooltip from '../Tooltip/ToolCancelledToolTip';

export default function ReportList({ onPress, data }) {
  const labels = ['Tool', 'Description'];
  let listData = [];

  listData = data.map((item) => {
    console.log(item.status);
    const name = `${item.partNumber} (${item.serialNumber})`;
    return [
      <TouchableOpacity onPress={() => onPress(item)}>
        <ToolCancelledTooltip
          text={name}
          isUnderlined
          isCancelled={item.status === 'TC'}
        ></ToolCancelledTooltip>
      </TouchableOpacity>,
      <Text>{item.partDescription || item.nomenclature}</Text>,
    ];
  });

  return <List tableHead={labels} tableRows={listData} />;
}
