import { View } from 'react-native';
import Button from '../../presentational/Button';
import { Badge } from '@rneui/themed';

function useOnPress(navigation) {
  return () => {
    navigation.navigate('Message Center');
  };
}

export default function GoToMessageCenterButton({
  navigation,
  children = 'Message Center',
  unreadCount = 0,
  ...props
}) {
  const onPress = useOnPress(navigation);
  return (
    <View>
      <Button onPress={onPress} fullWidth {...props}>
        {children}
      </Button>
      {unreadCount > 0 && (
        <Badge
          status='error'
          value={unreadCount}
          containerStyle={{ position: 'absolute', top: -10, right: -12 }}
        />
      )}
    </View>
  );
}
