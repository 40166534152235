import { useStoreActions } from 'easy-peasy';
import Button from '../../presentational/Button';

export default function SelectEngineButton({ children = 'Engines', ...props }) {
  const { setShowEnginesModalAction } = useStoreActions((actions) => actions);
  return (
    <Button
      onPress={() => setShowEnginesModalAction(true)}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}
