import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchBar from '../../presentational/SearchBar';

function TenantsSearchBar() {
  const { searchInput } = useStoreState((state) => state.tenant);
  const { setTenantSearchInputAction } = useStoreActions((actions) => actions);

  function clearSearchInput() {
    return () => setTenantSearchInputAction('');
  }

  return (
    <SearchBar
      value={searchInput}
      onChangeText={(newVal) => setTenantSearchInputAction(newVal)}
      placeholder='Search tenants...'
      onClearText={clearSearchInput}
      onCancel={clearSearchInput}
    />
  );
}

export default TenantsSearchBar;
