import { action } from 'easy-peasy';

const actions = {
  setTenantEngineDataAction: action(
    (state, { currentEngines, prostEngines }) => {
      state.engines.currentEngines = prostEngines;
      state.engines.selectedEngines = currentEngines;
      state.engines.engineOptions = prostEngines.map((item) => {
        const selected = currentEngines.find(
          (engine) => engine.engineFamilyId === item.EngineFamilyID
        );
        return {
          label: item.EngineFamily,
          value: item.EngineFamilyID,
          isSelected: !!selected,
          id: selected?.id,
        };
      });
    }
  ),
  selectTenantEngineOptionAction: action((state, item) => {
    state.engines.engineOptions = state.engines.engineOptions.map((option) => {
      if (option.label === item.label) {
        return { ...option, isSelected: item.isSelected, id: item.id };
      }
      return option;
    });
  }),
  setShowEnginesModalAction: action((state, show) => {
    state.engines.showEnginesModal = show;
  }),
  setEngineListAction: action((state, engines) => {
    state.engines.list = engines;
  }),
  setEngineFamilySearchInputAction: action((state, input) => {
    state.engines.searchInput = input;
  }),
  setSelectedEngineAction: action((state, input) => {
    state.engines.selectedEngine = {
      ...state.engines.selectedEngine,
      ...input,
    };
  }),
  setShowVariantModalAction: action((state, show) => {
    state.engines.showVariantModal = show;
  }),
  setVariantInputAction: action((state, { input, index }) => {
    state.engines.variantInput = { ...input };
    state.engines.editIndex = index;
  }),
  addVariant: action((state) => {
    if (state.engines.editIndex) {
      state.engines.selectedEngine.variants[state.engines.editIndex] =
        state.engines.variantInput;
    } else {
      state.engines.selectedEngine.variants.push(state.engines.variantInput);
    }

    state.engines.showVariantModal = false;
  }),
};

export default actions;
