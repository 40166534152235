import { ActivityIndicator, Dimensions, View } from 'react-native';

import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Text from '../../presentational/Text';
import Center from '../../presentational/Center';
import Page from './Page';

function DefaultPage({ navBar, body, title, loading = false, ...props }) {
  return (
    <Page.Protected {...props}>
      <View style={{ flex: 1, height: '100%' }}>
        {loading ? (
          <Center.Screen>
            <Text
              style={{
                fontFamily: 'Barlow_600SemiBold',
                fontSize: 30,
                position: 'absolute',
                top: '35%',
              }}
            >
              This could take a few moments...
            </Text>
            <ActivityIndicator
              size='large'
              color='#AB2328'
              style={{
                alignSelf: 'center',
                position: 'absolute',
                top: Dimensions.get('window').height / 2 - 70,
              }}
            />
          </Center.Screen>
        ) : (
          <Row style={{ flex: 1, height: '100%' }}>
            <Col
              xs={4}
              md={4}
              style={{ backgroundColor: 'white', height: '100%' }}
            >
              <Row style={{ borderBottom: '2px solid #0088CE' }}>
                <Col xs={12} md={12}>
                  <Text
                    style={{
                      color: '#0088CE',
                      fontSize: '1.8vh',
                      fontWeight: '600',
                    }}
                  >
                    {title}
                  </Text>
                </Col>
              </Row>
              {navBar}
            </Col>
            <Col
              xs={8}
              md={8}
              style={{ backgroundColor: '#f8f9fa', height: '100%' }}
            >
              {body}
            </Col>
          </Row>
        )}
      </View>
    </Page.Protected>
  );
}

export default DefaultPage;
