import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import {
  Platform,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  View,
} from 'react-native';
import { useState } from 'react';
import { Icon, ListItem } from '@rneui/themed';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import Input from '../../presentational/Input';
import Text from '../../presentational/Text';

export default function EngineReportModal({ onPress, ...props }) {
  const [serialNumber, setSerialNumber] = useState();
  const {
    tools: { showEngineReportModal, engineReportLoading, toolsByEngine },
  } = useStoreState((state) => state);

  const { setEngineReportModalAction, getToolsByEngineThunk } = useStoreActions(
    (actions) => actions
  );
  return (
    <CustomModal
      onClose={() => {
        setSerialNumber('');
        setEngineReportModalAction(false);
      }}
      isVisible={showEngineReportModal}
      title='Engine Report'
      style={{
        height: Platform.OS == 'web' ? '50vh' : '50%',
        width: Platform.OS == 'web' ? '70vh' : '70%',
      }}
    >
      <ScrollView
        style={{
          height: Platform.OS == 'web' ? '40vh' : '40%',
        }}
      >
        <Row>
          <Col>
            <Input
              placeholder='Serial Number'
              value={serialNumber}
              onChangeText={(value) => setSerialNumber(value)}
              rightIcon={
                <>
                  <TouchableOpacity
                    disabled={!serialNumber}
                    onPress={() => {
                      getToolsByEngineThunk(serialNumber);
                    }}
                  >
                    <Icon type='entypo' name='magnifying-glass' />
                  </TouchableOpacity>
                </>
              }
            />
          </Col>
        </Row>
        {engineReportLoading && (
          <View
            pointerEvents='none'
            style={{
              paddingTop: '2rem',

              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#F5FCFF88',
              minHeight: '4rem',
              zIndex: 1000,
            }}
          >
            <ActivityIndicator size='large' />
          </View>
        )}
        {!engineReportLoading && (
          <>
            {toolsByEngine?.map((tool) => {
              return (
                <>
                  <ListItem key={tool.key}>
                    <ListItem.Content>
                      <ListItem.Title>
                        <Text
                          style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                          }}
                        >
                          Work Order: {tool.workOrder} ({tool.usedDate})
                        </Text>
                      </ListItem.Title>
                      <ListItem.Subtitle>
                        <Text>User: {tool.user}</Text>
                      </ListItem.Subtitle>
                    </ListItem.Content>
                  </ListItem>
                  {tool.tools?.map((item, i) => {
                    return (
                      <ListItem containerStyle={{ marginLeft: '1rem' }} key={i}>
                        <ListItem.Content>
                          <ListItem.Title>
                            <TouchableOpacity onPress={() => onPress(item)}>
                              <Text
                                style={{
                                  textDecoration: 'underline',
                                }}
                              >
                                {item.partNumber} ({item.serialNumber})
                              </Text>
                            </TouchableOpacity>
                          </ListItem.Title>
                          <ListItem.Subtitle>
                            {item.description}
                          </ListItem.Subtitle>
                        </ListItem.Content>
                      </ListItem>
                    );
                  })}
                </>
              );
            })}
          </>
        )}
      </ScrollView>
    </CustomModal>
  );
}
