import { View } from 'react-native';
import Drawer from '../../presentational/Drawer';
import Button from '../Button';
import styled from 'rn-css';
import { useStoreState } from 'easy-peasy';
import { pxToDp } from '../../../utilities/general';

const Container = styled.View`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

function DrawerContent({ state, navigation }) {
  const tenantId = useStoreState((state) => state.tenant.input.id);

  return (
    <View style={{ height: '100%', flex: 1 }}>
      <Container>
        {state.routes
          .filter(
            (route) =>
              route.name === 'Home / Tenants' ||
              (route.name === 'Users' && tenantId) ||
              (route.name === 'Tools' && tenantId) ||
              (route.name === 'Manage Configuration' && tenantId) ||
              (route.name === 'Manage Location' && tenantId) ||
              (route.name === 'Locations' && tenantId)
          )
          .map((route) => (
            <Drawer.Tab
              key={route.key}
              onPress={() => navigation.navigate(route.name)}
              style={{
                backgroundColor: 'lightgray',
                marginTop: pxToDp(15),
              }}
            >
              {route.name}
            </Drawer.Tab>
          ))}

        <View style={{ marginTop: 'auto' }}>
          <Button.Logout navigation={navigation} />
        </View>
      </Container>
    </View>
  );
}

export default DrawerContent;
