import { useStoreState } from 'easy-peasy';
import { StatusBar } from 'expo-status-bar';
import Message from '../../presentational/Message';
import { ActivityIndicator, Dimensions, ScrollView, View } from 'react-native';
import Center from '../../presentational/Center';
import { useState, useEffect } from 'react';
import Text from '../../presentational/Text';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

function Page({ children, ...props }) {
  const { alerts, loading } = useStoreState((state) => state.status);

  const [showMessage, setShowMessage] = useState(false);
  const [loadingStartTime, setLoadingStartTime] = useState(null);

  useEffect(() => {
    if (loading) {
      setLoadingStartTime(new Date());
    } else {
      setLoadingStartTime(null);
      setShowMessage(false);
    }
  }, [loading]);

  useEffect(() => {
    let timer;
    if (loadingStartTime) {
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [loadingStartTime]);

  return (
    <View {...props} style={{ flex: 1, height: '94vh', width: '100vw' }}>
      <ScrollView contentContainerStyle={{ flex: 1, height: '100%' }}>
        <KeyboardAwareScrollView
          contentContainerStyle={{ flex: 1, height: '100%' }}
        >
          <StatusBar style='auto' />

          {alerts.map(({ type, ...alert }, index) => {
            let Component = Message;
            if (type === 'error') Component = Message.Error;
            else if (type === 'success') Component = Message.Success;
            return <Component key={index}>{alert.message}</Component>;
          })}

          {loading ? (
            <Center.Screen>
              {showMessage && (
                <Text
                  style={{
                    fontFamily: 'Barlow_600SemiBold',
                    fontSize: 30,
                    position: 'absolute',
                    top: '35%',
                  }}
                >
                  This could take a few moments...
                </Text>
              )}
              <ActivityIndicator
                size='large'
                color='#AB2328'
                style={{
                  alignSelf: 'center',
                  position: 'absolute',
                  top: Dimensions.get('window').height / 2 - 70,
                }}
              />
            </Center.Screen>
          ) : (
            children
          )}
        </KeyboardAwareScrollView>
      </ScrollView>
    </View>
  );
}

export default Page;
