import List from '../../presentational/List';
import TenantsList from './TenantsList';
import UsersList from './UsersList';
import ToolsList from './ToolsList';
import LocationsList from './LocationsList';
import DropdownList from './DropdownList';
import LabelList from './LabelDropdownList';
import RoleList from './RoleList';
import DocumentsList from './DocumentsList';
import WorkPackageList from './WorkPackageList';
import WorkPackageToolList from './WorkPackageToolList';
import InspectionList from './InspectionList';
import InspectionItemsList from './InspectionItemsList';
import MyToolsList from './MyToolsList';
import ToolStatusList from './ToolStatusList';
import MyWorkPackagesList from './MyWorkPackagesList';
import ReservationToolsList from './ReservationToolsList';
import ReservationInformationList from './ReservationList';
import StorageLocationsList from './StorageLocationsList';
import BrokenToolsList from './BrokenToolsList';
import BrokenToolHistoryList from './BrokenToolHistoryList';
import ToolCategoryList from './ToolCategoryList';
import OEMStatusList from './OEMStatusList';
import WorkPackageReservationList from './WorkPackageReservationList';
import MessageList from './MessageList';
import InspectionsList from './InspectionsList';
import ToolTransfersList from './ToolTransfersList';
import ScoreCardList from './ScoreCardList';
import ReportList from './ReportList';
import EngineFamilyList from './EngineFamilyList';
import EngineVariantList from './EngineVariantList';
import RegionList from './RegionList';

List.Tenants = TenantsList;
List.Users = UsersList;
List.Tools = ToolsList;
List.Locations = LocationsList;
List.Dropdowns = DropdownList;
List.Labels = LabelList;
List.Roles = RoleList;
List.Documents = DocumentsList;
List.WorkPackage = WorkPackageList;
List.WorkPackageTools = WorkPackageToolList;
List.Inspection = InspectionList;
List.InspectionItems = InspectionItemsList;
List.MyTools = MyToolsList;
List.MyWorkPackages = MyWorkPackagesList;
List.ToolStatus = ToolStatusList;
List.Reservation = ReservationToolsList;
List.ReservationInformation = ReservationInformationList;
List.StorageLocation = StorageLocationsList;
List.BrokenTools = BrokenToolsList;
List.BrokenToolHistory = BrokenToolHistoryList;
List.ToolCategory = ToolCategoryList;
List.OEMStatus = OEMStatusList;
List.WorkPackageReservations = WorkPackageReservationList;
List.Messages = MessageList;
List.Inspections = InspectionsList;
List.Transfers = ToolTransfersList;
List.ScoreCard = ScoreCardList;
List.ReportList = ReportList;
List.EngineFamily = EngineFamilyList;
List.EngineVariant = EngineVariantList;
List.Regions = RegionList;

export default List;
