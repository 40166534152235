import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomModal from '../../presentational/Modal';
import { Platform, ScrollView } from 'react-native';
import { ListItem } from '@rneui/themed';
import { FontAwesome5 } from '@expo/vector-icons';
import { remToDp } from '../../../utilities/general';

export default function EngineSelectModal() {
  const {
    engines: { showEnginesModal, engineOptions },
  } = useStoreState((state) => state);

  const {
    setShowEnginesModalAction,
    upsertTenantEngineThunk,
    deleteTenantEngineThunk,
    selectTenantEngineOptionAction,
  } = useStoreActions((actions) => actions);
  return (
    <CustomModal
      onClose={() => {
        setShowEnginesModalAction(false);
      }}
      isVisible={showEnginesModal}
      title='Engines'
      style={{
        height: Platform.OS == 'web' ? '50vh' : '50%',
        width: Platform.OS == 'web' ? '30vw' : '30%',
      }}
    >
      <ScrollView
        style={{
          height: Platform.OS == 'web' ? '40vh' : '40%',
        }}
      >
        {engineOptions.map((item) => {
          return (
            <ListItem
              bottomDivider
              onPress={async () => {
                if (!item.isSelected) {
                  await upsertTenantEngineThunk(item);
                } else {
                  await deleteTenantEngineThunk(item);
                }
              }}
            >
              <ListItem.CheckBox
                onPress={async () => {
                  if (!item.isSelected) {
                    await upsertTenantEngineThunk(item);
                  } else {
                    await deleteTenantEngineThunk(item);
                  }
                }}
                checkedIcon={
                  <FontAwesome5
                    name='check-square'
                    size={remToDp(1.5)}
                    color='#007AFF'
                  />
                }
                uncheckedIcon={
                  <FontAwesome5
                    name='square'
                    size={remToDp(1.5)}
                    color='#007AFF'
                  />
                }
                checked={item.isSelected}
              />
              <ListItem.Content>
                <ListItem.Title>{item.label}</ListItem.Title>
              </ListItem.Content>
            </ListItem>
          );
        })}
      </ScrollView>
    </CustomModal>
  );
}
