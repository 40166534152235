import { useStoreState } from 'easy-peasy';
import { formatStringForSearch } from '../../../utilities/general';
import { FlatList } from 'react-native';
import { ListItem } from '@rneui/themed';
import Text from '../../presentational/Text';

function TenantsList({ navigation }) {
  const { searchResults } = useStoreState((state) => ({
    ...state.tenant,
    searchResults: state.tenant.searchResults.filter((tenant) => {
      let filteredBy = formatStringForSearch(tenant.name).includes(
        formatStringForSearch(state.tenant.searchInput)
      );
      if (state.tenant.activeFilter === 'All') {
        return filteredBy;
      } else {
        return (
          filteredBy &&
          (state.tenant.activeFilter === 'Active'
            ? tenant.active
            : !tenant.active)
        );
      }
    }),
  }));

  return (
    <FlatList
      style={{ overflow: 'auto', height: '100%' }}
      data={searchResults}
      renderItem={({ item }) => {
        return (
          <ListItem
            onPress={() => navigation.push('Edit Tenant', { id: item.id })}
            style={{
              boxShadow: '0px 0px 5px grey',
              borderRadius: '12px',
              overflow: 'hidden',
              margin: '8px',
            }}
          >
            <ListItem.Content>
              <ListItem.Title>
                <Text
                  style={{
                    color: '#00205B',
                    fontSize: '1.6vh',
                    fontWeight: '600',
                  }}
                >
                  {item.name}
                </Text>
              </ListItem.Title>
              <ListItem.Subtitle>
                <Text
                  style={{
                    color: '#76787A',
                    fontSize: '1.4vh',
                    fontWeight: '600',
                  }}
                >
                  {`${item.userCnt} Users `}&bull;
                  {` ${item.locationCnt} Locations `}&bull;
                  {` ${item.tenantCnt} Tenants `}&bull;
                  {` ${item.numofchildren} Children `}&bull;
                </Text>
                <Text
                  style={{
                    color: item.active ? 'green' : '#AB2328',
                    fontSize: '1.4vh',
                    fontWeight: '600',
                  }}
                >{` ${item.active ? 'Active' : 'Inactive'}`}</Text>
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        );
      }}
      keyExtractor={(item) => item.id}
    />
  );
}

export default TenantsList;
