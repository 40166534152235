import { useStoreActions, useStoreState } from 'easy-peasy';
import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { vhToDp } from '../../../utilities/general';
import FormSection from '../../presentational/FormSection';
import UniversalTextInput from '../../presentational/TextInput';
import { ScrollView } from 'react-native-gesture-handler';
import Button from '../../presentational/Button';

const FormContainer = styled(View)`
  flex: 1;
  height: 100%;
`;

const FormHeader = styled.View`
  flex-direction: row;
  padding: ${vhToDp(3)}px;
  border-bottom-width: 1px;
  border-bottom-color: #edf2f7;
  width: 100%;
  justify-content: space-between;
`;

const FormTitle = styled.Text`
  font-size: ${vhToDp(2.4)}px;
  font-weight: 700;
  color: #00205b;
`;

const FormSubtitle = styled.Text`
  font-size: ${vhToDp(1.4)}px;
  color: #6b7280;
  margin-top: ${vhToDp(0.5)}px;
`;

function RegionForm({ buttons, ...props }) {
  const {
    region: { input },
    tenant,
  } = useStoreState((state) => state);

  const { setRegionInputAction, upsertRegionThunk, resetRegionInputAction } =
    useStoreActions((actions) => actions);

  return (
    <FormContainer>
      <FormHeader>
        <View>
          <FormTitle>Region</FormTitle>
          <FormSubtitle>Add or edit regions</FormSubtitle>
        </View>
        <Row>
          <Col xs={6} md={6}>
            <Button
              onPress={async () => {
                await resetRegionInputAction();
              }}
              fullWidth
              variant='secondary'
            >
              Clear
            </Button>
          </Col>
          <Col xs={6} md={6}>
            <Button
              onPress={async () => {
                await upsertRegionThunk({
                  ...input,
                  tenantid: tenant.input.id,
                });
              }}
              disabled={!input.regionname}
              fullWidth
            >
              Save
            </Button>
          </Col>
        </Row>
      </FormHeader>
      <ScrollView>
        <Row>
          <Col xs={12} md={6}>
            <FormSection title={'Details'}>
              <UniversalTextInput
                placeholder='Region name'
                label='Name'
                value={input.regionname}
                onChangeText={(regionname) =>
                  setRegionInputAction({ regionname })
                }
                required
                editable
              />
              <UniversalTextInput
                placeholder='Region Type'
                label='Region Type'
                value={input.regiontype}
                onChangeText={(regiontype) =>
                  setRegionInputAction({ regiontype })
                }
                editable
              />
              <UniversalTextInput
                placeholder='Region Code'
                label='Region Code'
                value={input.regioncode}
                onChangeText={(regioncode) =>
                  setRegionInputAction({ regioncode })
                }
                editable
              />
            </FormSection>
          </Col>
        </Row>
      </ScrollView>
    </FormContainer>
  );
}

export default RegionForm;
