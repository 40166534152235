const state = {
  engines: {
    currentEngines: [],
    selectedEngines: [],
    engineOptions: [],
    list: [],
    filteredList: [],
    searchInput: '',
    selectedEngine: {
      variants: [],
    },
    showVariantModal: false,
    variantInput: {},
    editIndex: null,
    showEnginesModal: false,
  },
};

export { state as default };
